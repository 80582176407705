<div [formGroup]="companyBranchForm">
    <div formGroupName="contact">
        <div class="grid grid-cols-1 gap-6 md:grid-cols-2">
            <div class="">
                <rza-mean-input formControlName="lastname" label="Nachname"></rza-mean-input>
            </div>
            <div class="">
                <rza-mean-input formControlName="firstname" label="Vorname"></rza-mean-input>
            </div>
        </div>
        <div class="mt-6">
            <rza-mean-checkbox formControlName="isPep" label="ist eine politisch exponierte Person nach PEP"></rza-mean-checkbox>
        </div>
        <div formGroupName="address">
            <div class="mt-6">
                <div class="">
                    <rza-mean-input formControlName="street" label="Strasse"></rza-mean-input>
                </div>
            </div>
            <div class="mt-6 grid grid-cols-1 gap-6 md:grid-cols-2">
                <div class="">
                    <rza-mean-input formControlName="zip" label="PLZ"></rza-mean-input>
                </div>
                <div class="">
                    <rza-mean-input formControlName="city" label="Ort"></rza-mean-input>
                </div>
            </div>
            <div class="mt-6">
                <div class="">
                    <rza-mean-input formControlName="country" label="Land"></rza-mean-input>
                </div>
            </div>
        </div>
        <div class="mt-6">
            <div class="">
                <rza-mean-input formControlName="email" label="E-Mail-Adresse"></rza-mean-input>
            </div>
        </div>
        <div class="mt-6 grid grid-cols-1 gap-6 md:grid-cols-2">
            <div class="">
                <rza-mean-input formControlName="phone" label="Telefon-Nr."></rza-mean-input>
            </div>
            <div class="">
                <rza-mean-input formControlName="fax" label="Fax (optional)"></rza-mean-input>
            </div>
        </div>
    </div>
    <div class="mt-6">
        <div class="">
            <rza-mean-input formControlName="institutionCode" label="IK-Nummer (nachreichbar)"></rza-mean-input>
        </div>
        <rza-mean-info-toggle
            header="IK-Nummer gerade nicht zur Hand?"
            text="Diese können Sie auch später nachreichen."
        ></rza-mean-info-toggle>
    </div>
</div>
