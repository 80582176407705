export const environment = {
    production: true,
    apiUrl: 'api',
    removeConfiguration: false,
    configVersion: '1.1.0',
    companyData: {
        iban: '',
        institutionCode: '',
        name: '',
        firstName: '',
        lastName: '',
        salutation: '',
        title: '',
        street: '',
        zip: '',
        city: '',
        country: '',
        email: '',
        phone: '',
        taxId: '',
        taxCountryCode: '',
        bankInstitute: '',
        accountOwner: '',
        bic: '',
    },
};
