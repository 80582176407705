<rza-mean-card cardClasses="{{ 'bg-' + product.color }}">
    <ng-template #header>
        <div class="text-center font-medium text-white">
            {{ product.name }}
        </div>
    </ng-template>
    <ng-template #body>
        <rza-mean-revenue-form [product]="product"></rza-mean-revenue-form>
    </ng-template>
</rza-mean-card>
