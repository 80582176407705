<div [@sidebar]="isOpen ? 'open' : 'closed'" class="absolute left-0 top-0 bottom-0 bg-white pt-16 shadow">
    <div class="absolute top-8 right-0 -m-3">
        <div
            [@rotateToggle]="isOpen ? 'open' : 'closed'"
            class="flex h-6 w-6 cursor-pointer items-center justify-center rounded-full bg-blue-100"
            (click)="toggleSize()"
        >
            <i class="pi pi-angle-right text-info"></i>
        </div>
    </div>

    <div class="overflow-x-hidden">
        <div class="flex w-[400px]">
            <!--            <div>-->
            <!--                <i *ngFor="let icon of icons"-->
            <!--                   class="pi cursor-pointer text-muted hover:text-primary px-2 mr-3 {{icon}}" style="font-size: 1.5rem;"-->
            <!--                ></i>-->
            <!--            </div>-->
            <div class="flex-grow">
                <rza-mean-sidebar-item iconClass="pi-sliders-h">
                    <div class="grid grid-cols-1">
                        <rza-mean-revenue-card *ngFor="let product of settlementProducts$ | async; let index = index" [product]="product">
                        </rza-mean-revenue-card>
                    </div>
                </rza-mean-sidebar-item>
                <rza-mean-sidebar-item iconClass="pi-shopping-cart"> cart </rza-mean-sidebar-item>
            </div>
        </div>
    </div>
</div>
