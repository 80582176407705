import { Component, Input, OnInit } from '@angular/core';
import { IconName } from '@fortawesome/fontawesome-svg-core';

@Component({
    selector: 'rza-mean-label',
    templateUrl: './label.component.html',
    styleUrls: ['./label.component.scss'],
})
export class LabelComponent implements OnInit {
    @Input() text: string | undefined;
    @Input() icon: IconName | undefined;
    constructor() {}

    ngOnInit(): void {}
}
