import { Component, Input } from '@angular/core';
import { Product } from '../../shared/interfaces';

@Component({
    selector: 'rza-mean-revenue-card',
    templateUrl: './revenue-card.component.html',
    styleUrls: ['./revenue-card.component.scss'],
})
export class RevenueCardComponent {
    @Input() product!: Product;
}
