import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { CompanySubsidiary } from '../../../shared/interfaces';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subject, takeUntil } from 'rxjs';

@Component({
    selector: 'rza-mean-company-branch-form',
    templateUrl: './company-branch-form.component.html',
    styleUrls: ['./company-branch-form.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CompanyBranchFormComponent implements OnInit, OnDestroy {
    @Input() companyBranch: CompanySubsidiary | null = null;
    @Output() isValid = new EventEmitter<boolean>();
    @Output() updatedCompanyBranch: EventEmitter<CompanySubsidiary> = new EventEmitter<CompanySubsidiary>();

    companyBranchForm: FormGroup;
    private unsubscribe$ = new Subject();

    constructor(private fb: FormBuilder) {
        this.companyBranchForm = this.fb.group({
            contact: this.fb.group({
                firstname: ['', Validators.required, null, { updateOn: 'blur' }],
                lastname: ['', Validators.required, null, { updateOn: 'blur' }],
                email: ['', [Validators.required, Validators.email], null, { updateOn: 'blur' }],
                phone: ['', null, null, { updateOn: 'blur' }],
                fax: ['', null, null, { updateOn: 'blur' }],
                isPep: [false],
                address: this.fb.group({
                    street: ['', Validators.required, null, { updateOn: 'blur' }],
                    zip: ['', Validators.required, null, { updateOn: 'blur' }],
                    city: ['', Validators.required, null, { updateOn: 'blur' }],
                    country: ['', null, null, { updateOn: 'blur' }],
                }),
            }),
            institutionCode: ['', null, null, { updateOn: 'blur' }],
        });
    }

    ngOnInit(): void {
        if (this.companyBranch != null) {
            this.companyBranchForm.patchValue(this.companyBranch);
            (this.companyBranchForm.controls['contact'] as FormGroup).controls['address'].patchValue(this.companyBranch.contact?.address);
        }

        this.companyBranchForm.valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe((form) => {
            this.updatedCompanyBranch.emit(form);
            this.isValid.emit(this.companyBranchForm.valid);
        });
        setTimeout(() => this.isValid.emit(this.companyBranch != null), 0);
    }

    ngOnDestroy() {
        if (this.companyBranchForm.valid) {
            this.updatedCompanyBranch.emit(this.companyBranchForm.value);
        }
        this.unsubscribe$.next(null);
        this.unsubscribe$.complete();
    }
}
