import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
    selector: 'rza-mean-sidebar-item',
    templateUrl: './sidebar-item.component.html',
    styleUrls: ['./sidebar-item.component.scss'],
})
export class SidebarItemComponent implements OnInit {
    @Input() iconClass: string | undefined;

    @Output() toggleSize = new EventEmitter();

    constructor() {}

    ngOnInit(): void {}
}
