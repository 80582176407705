import { ChangeDetectionStrategy, Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { CompanyPerson } from '../../../shared/interfaces';
import { filter, Subject, takeUntil } from 'rxjs';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CountryEnum, SalutationEnum, TitleEnum } from '@rza-mean/api-interfaces';
import { Store } from '@ngrx/store';
import { selectCompanyContact } from '../../../store/company/company.selectors';
import { environment } from '../../../../environments/environment';

@Component({
    selector: 'rza-mean-company-person-form',
    templateUrl: './company-person-form.component.html',
    styleUrls: ['./company-person-form.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CompanyPersonFormComponent implements OnInit, OnDestroy {
    @Output() isValid: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() updateCompanyPerson: EventEmitter<CompanyPerson> = new EventEmitter<CompanyPerson>();

    salutationList = Object.values(SalutationEnum);
    titleList = Object.values(TitleEnum);
    countryList = Object.values(CountryEnum);

    companyPersonForm: FormGroup;
    private unsubscribe$ = new Subject();

    constructor(private fb: FormBuilder, private store: Store) {
        this.companyPersonForm = this.fb.group(
            {
                salutation: [environment.companyData?.salutation ?? undefined, Validators.required],
                title: [environment.companyData?.title ?? undefined],
                firstname: [environment.companyData?.firstName ?? '', Validators.required],
                lastname: [environment.companyData?.lastName ?? '', Validators.required],
                address: this.fb.group({
                    street: [environment.companyData?.street ?? '', Validators.required],
                    zip: [environment.companyData?.zip ?? '', Validators.required],
                    city: [environment.companyData?.city ?? '', Validators.required],
                    country: [environment.companyData?.country ?? '', Validators.required],
                }),
                email: [environment.companyData?.email ?? '', [Validators.required, Validators.email]],
                phone: [environment.companyData?.phone ?? '', Validators.required],
            },
            { updateOn: 'blur' }
        );
    }

    ngOnInit(): void {
        this.store
            .select(selectCompanyContact)
            .pipe(
                filter((contact) => contact != null),
                takeUntil(this.unsubscribe$)
            )
            .subscribe((contact) => {
                if (contact != null) {
                    this.companyPersonForm.patchValue(contact, { emitEvent: false });
                }
                this.isValid.emit(this.companyPersonForm.valid);
            });
        this.companyPersonForm.valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe((form) => {
            this.isValid.emit(this.companyPersonForm.valid);
            this.updateCompanyPerson.emit(form);
        });
    }

    ngOnDestroy() {
        this.unsubscribe$.next(null);
        this.unsubscribe$.complete();
    }
}
