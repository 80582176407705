import { Directive, HostListener } from '@angular/core';

@Directive({
    selector: '[rzaMeanStopPropagation]',
})
export class StopPropagationDirective {
    @HostListener('click', ['$event'])
    public onClick(event: { stopPropagation: () => void; preventDefault: () => void }): void {
        event.stopPropagation();
        event.preventDefault();
    }
}
