<div [formGroup]="contactPersonForm" class="grid">
    <div>
        <rza-mean-radio [labels]="type" [checkedLabel]="contactPersonType" formControlName="type"></rza-mean-radio>
    </div>
    <div class="mt-2 grid grid-cols-1 gap-6 md:grid-cols-2">
        <div class="">
            <rza-mean-input formControlName="lastname" label="Nachname"></rza-mean-input>
        </div>
        <div class="">
            <rza-mean-input formControlName="firstname" label="Vorname"></rza-mean-input>
        </div>
    </div>
    <div formGroupName="address">
        <div class="mt-6">
            <div class="">
                <rza-mean-input formControlName="street" label="Strasse"></rza-mean-input>
            </div>
        </div>
        <div class="mt-6 grid grid-cols-1 gap-6 md:grid-cols-2">
            <div class="">
                <rza-mean-input formControlName="zip" label="PLZ"></rza-mean-input>
            </div>
            <div class="">
                <rza-mean-input formControlName="city" label="Ort"></rza-mean-input>
            </div>
        </div>
    </div>
    <div class="mt-6 grid grid-cols-1 gap-6 md:grid-cols-2">
        <div class="">
            <rza-mean-input formControlName="phone" label="Telefon-Nr."></rza-mean-input>
        </div>
        <div class="">
            <rza-mean-input formControlName="email" label="E-Mail"></rza-mean-input>
        </div>
    </div>
    <div class="mt-6">
        <rza-mean-input formControlName="position" label="Funktion"></rza-mean-input>
    </div>
    <div class="mt-6">
        <rza-mean-checkbox formControlName="isOKCContact" label="Ansprechpartner für das Online Kundencenter"></rza-mean-checkbox>
    </div>
    <div *ngIf="contactPersonType === contactPersonTypeEnum.BENEFICIALOWNER">
        <hr class="my-4" />
        <div class="mb-6">
            <rza-mean-select formControlName="country" [options]="countries" selectId="country"></rza-mean-select>
            <div class="mt-6">
                <rza-mean-checkbox
                    formControlName="isPep"
                    label="die Person ist eine politisch exponierte Person nach PEP"
                ></rza-mean-checkbox>
            </div>
        </div>
    </div>
</div>
