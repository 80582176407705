import { Component, Input } from '@angular/core';

@Component({
    selector: 'rza-mean-info-toggle',
    templateUrl: './info-toggle.component.html',
    styleUrls: ['./info-toggle.component.scss'],
})
export class InfoToggleComponent {
    @Input() header = '';
    @Input() text = '';
}
