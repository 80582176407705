import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ContactPerson } from '../../../shared/interfaces';
import { ContactPersonTypeEnum, CountryEnum } from '@rza-mean/api-interfaces';
import { Subject, takeUntil } from 'rxjs';

@Component({
    selector: 'rza-mean-contact-person-form',
    templateUrl: './contact-person-form.component.html',
    styleUrls: ['./contact-person-form.component.scss'],
})
export class ContactPersonFormComponent implements OnInit, OnDestroy {
    @Input() contactPerson: ContactPerson | null = null;
    @Output() updatedContactPerson: EventEmitter<ContactPerson> = new EventEmitter<ContactPerson>();
    @Output() isValid: EventEmitter<boolean> = new EventEmitter<boolean>();
    contactPersonForm: FormGroup;
    type = Object.values(ContactPersonTypeEnum);
    contactPersonTypeEnum = ContactPersonTypeEnum;
    private unsubscribe$ = new Subject();
    countries = Object.values(CountryEnum);

    constructor(private fb: FormBuilder) {
        this.contactPersonForm = this.fb.group({
            firstname: ['', Validators.required],
            lastname: ['', Validators.required],
            email: ['', [Validators.required, Validators.email]],
            phone: [''],
            address: this.fb.group({
                street: ['', Validators.required],
                zip: ['', Validators.required],
                city: ['', Validators.required],
            }),
            position: ['', Validators.required],
            type: [ContactPersonTypeEnum.CONTACT, Validators.required],
            country: [CountryEnum.GERMANY],
            isOKCContact: [false],
            isPep: [false],
        });
    }

    ngOnInit(): void {
        if (this.contactPerson != null) {
            this.contactPersonForm.patchValue(this.contactPerson);
        }
        this.contactPersonForm.controls['type'].valueChanges
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe((type) => this.contactPersonTypeChanged(type));
        // TODO: reduce emits
        this.contactPersonForm.valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe((form) => {
            this.isValid.emit(this.contactPersonForm.valid);
        });
        setTimeout(() => this.isValid.emit(this.contactPerson != null), 0);
    }

    ngOnDestroy() {
        if (this.contactPersonForm.valid) {
            this.updatedContactPerson.emit(this.contactPersonForm.value);
        }
        this.unsubscribe$.next(null);
        this.unsubscribe$.complete();
    }

    get contactPersonType() {
        return this.contactPersonForm.controls['type'].value;
    }

    contactPersonTypeChanged(type: string): void {
        const country = this.contactPersonForm.controls['country'];
        const isPep = this.contactPersonForm.controls['isPep'];

        if (type === ContactPersonTypeEnum.BENEFICIALOWNER) {
            country.enable();
            isPep.enable();
        } else {
            country.disable();
            isPep.disable();
        }
    }
}
