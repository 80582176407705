import { AfterViewInit, Component, ElementRef, forwardRef, Injector, Input, OnInit, ViewChild } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR, NgControl } from '@angular/forms';

@Component({
    selector: 'rza-mean-input',
    templateUrl: './input.component.html',
    styleUrls: ['./input.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => InputComponent),
            multi: true,
        },
    ],
})
export class InputComponent implements ControlValueAccessor, OnInit, AfterViewInit {
    @ViewChild('error') errorWrapper: ElementRef<HTMLElement> | undefined;

    @Input()
    label = '';

    @Input()
    placeholder = '';

    @Input()
    type = 'text';

    @Input()
    inputId: string | undefined;

    @Input()
    size: 'lg' | 'sm' | 'md' = 'md';

    @Input()
    max: string | number = '';

    @Input()
    min: string | number = '';

    @Input()
    textAppend: string | undefined;

    @Input()
    textAppendClasses: string | undefined = 'bg-primary-lighter';

    @Input()
    textPrepend: string | undefined;

    @Input()
    readonly = false;

    @Input()
    indicateValidity = true;

    @Input()
    inputClasses: string | undefined;

    @Input()
    autocomplete: string = 'on';

    disabled = false;
    value = '';
    generatedTd!: string;
    private control: FormControl | undefined;
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onChange: any = (val: string) => {};
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onTouched: any = () => {};

    constructor(private injector: Injector, private elementRef: ElementRef) {}

    ngOnInit() {
        this.generatedTd = Math.random()
            .toString(36)
            .replace('0.', '_' + this.label.trim().replace(' ', '_').toLowerCase() || 'nolabel');
    }

    ngAfterViewInit() {
        if (this.indicateValidity) {
            this.elementRef.nativeElement.classList.add('group');
            const ngControl = this.injector.get(NgControl, null);
            if (ngControl) {
                this.control = ngControl.control as FormControl;
            }
        }
    }

    registerOnChange(onChange: any) {
        this.onChange = onChange;
    }

    writeValue(value: any) {
        this.value = value;
    }

    registerOnTouched(onTouch: any) {
        this.onTouched = onTouch;
    }

    setDisabledState(disabled: boolean) {
        this.disabled = disabled;
    }

    change($event: Event) {
        this.onChange(($event.target as HTMLInputElement).value);
    }

    getId(): string {
        return this.inputId ?? this.generatedTd;
    }

    getInputSizeClasses(): string {
        switch (this.size) {
            case 'md':
                return 'px-4 py-3.5';
            case 'lg':
                return 'px-5 py-4 text-lg';
            case 'sm':
                return 'px-3 py-2.5 text-sm';
        }
    }

    getLabelSizeClasses(): string {
        switch (this.size) {
            case 'lg':
                return 'text-lg';
            case 'sm':
                return 'text-sm';
            default:
                return '';
        }
    }

    getInputClasses(): string[] {
        let classes: string[] = ['rounded-l', 'rounded-r'];
        if (this.textPrepend) {
            classes = classes.filter((c) => c !== 'rounded-l');
        }
        if (this.textAppend) {
            classes = classes.filter((c) => c !== 'rounded-r');
        }
        if (this.inputClasses) {
            classes.push(this.inputClasses);
        }
        return [...classes, ...this.getInputSizeClasses().split(' ')];
    }

    onBlur() {
        this.onTouched();
        if (this.indicateValidity && this.errorWrapper) {
            if (this.control?.touched && this.control.status === 'INVALID') {
                if (this.control.errors) {
                    const messages: string[] = [];
                    for (const errorKey in this.control.errors) {
                        if (errorKey === 'required') {
                            messages.push(`Das Feld '${this.label}' ist verpflichtend.`);
                        } else {
                            messages.push(this.control.errors[errorKey].value);
                        }
                    }
                    this.errorWrapper.nativeElement.innerHTML = messages.join('<br>');
                }
            }
            if (this.control?.errors == null) {
                this.errorWrapper.nativeElement.innerHTML = '';
            }
        }
    }
}
