import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Price, Product } from '../../shared/interfaces';
import { ConfigurationState } from '../../store/configuration/configuration.state';
import { Store } from '@ngrx/store';
import {
    selectConfiguredBundleId,
    selectIsProductInConfig,
    selectIsSubProductInConfig,
} from '../../store/configuration/configuration.selectors';
import { map, Observable, Subject, take, takeUntil } from 'rxjs';
import { ServiceProductActions } from '../../store/configuration/configuration.actions';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { PriceDependencyTypeEnum } from '@rza-mean/api-interfaces';
import { ProductService } from '../../services/product.service';
import { selectSelectedBundle } from '../../store/profession/profession.selectors';

@Component({
    selector: 'rza-mean-service-card',
    templateUrl: './service-card.component.html',
    styleUrls: ['./service-card.component.scss'],
})
export class ServiceCardComponent implements OnInit, OnDestroy {
    @Input() product!: Product;
    @Output() infoModal = new EventEmitter<{ title: string; content: string }>();

    subProducts!: Product[] | null;
    isSelected = false;
    isProductPartOfBundle = false;
    modalContentIsIframe = false;
    private unsubscribe$ = new Subject<void>();
    private selectedBundleId: string | undefined;
    constructor(private store: Store<ConfigurationState>, private domSanitizer: DomSanitizer, private productService: ProductService) {}

    ngOnInit(): void {
        this.modalContentIsIframe = this.product.modalContent?.content.includes('<iframe') || false;
        this.subProducts = this.product.products?.length ? this.product.products : null;
        this.store
            .select(selectIsProductInConfig(this.product._id))
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe((isSelected) => (this.isSelected = isSelected));
        this.store
            .select(selectConfiguredBundleId)
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe((bundleId) => (this.selectedBundleId = bundleId));
        this.store
            .select(selectSelectedBundle)
            .pipe(
                takeUntil(this.unsubscribe$),
                map((bundle) => bundle?.config?.configuredProfession?.products.find((prod) => prod._id === this.product._id) != null)
            )
            .subscribe((isIncluded) => (this.isProductPartOfBundle = isIncluded));
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    get prices$() {
        return this.productService.findAppliedPricesArray$(this.product);
    }

    get name(): string {
        if (this.subProducts?.length === 1) {
            return `${this.product.name} ${this.subProducts[0].name}`;
        }
        return this.product.name;
    }

    toggleSelection($event: boolean): void {
        if (!this.product.isObligatory) {
            this.isSelected = $event;
            if (this.isSelected) {
                this.store.dispatch(ServiceProductActions.addServiceProduct({ product: this.product }));
            } else {
                this.store.dispatch(ServiceProductActions.removeServiceProduct({ _id: this.product._id }));
            }
        }
    }

    getModalIframeContent(): SafeHtml | undefined {
        if (this.product.modalContent && this.modalContentIsIframe) {
            return this.domSanitizer.bypassSecurityTrustHtml(this.product.modalContent.content);
        }
        return undefined;
    }

    getSubProductPrice(subProduct: Product) {
        //TODO: use method below in the future
        // return this.productService.findAppliedPriceObject$(subProduct);
        if (subProduct.dependentPrices?.length) {
            const dependentPrice = subProduct.dependentPrices.find(
                (_) => _.type === PriceDependencyTypeEnum.BUNDLE && _.referenceId === this.selectedBundleId
            );
            if (dependentPrice) {
                return dependentPrice.price;
            }
        }
        if (subProduct.prices?.length) {
            return subProduct.prices[0];
        }
        return undefined;
    }

    selectSubProduct($event: boolean, subProduct: Product) {
        if ($event) {
            this.store.dispatch(ServiceProductActions.addServiceProduct({ product: this.product, subProduct: subProduct }));
        } else if (!this.isProductPartOfBundle) {
            this.isSubProductSelected$(subProduct)
                .pipe(take(1))
                .subscribe((isSelected) => {
                    if (isSelected) {
                        this.store.dispatch(ServiceProductActions.removeServiceProduct({ _id: this.product._id }));
                    }
                });
        }
    }

    isSubProductSelected$(subProduct: Product) {
        return this.store.select(selectIsSubProductInConfig(this.product._id, subProduct._id));
    }
}
