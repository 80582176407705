import {
    CalculationMethodEnum,
    ContactPersonTypeEnum,
    CountryEnum,
    DocumentCategoryEnum,
    FileTypeEnum,
    OperatorEnum,
    PriceDependencyTypeEnum,
    PriceUnitEnum,
    ProductCategoryEnum,
    ProductTypeEnum,
    ProfessionStatusEnum,
    RevenueTypeEnum,
    SalutationEnum,
    SettlementCenterEnum,
    SubmissionIntervalEnum,
    TaxCountryCodeEnum,
    TitleEnum,
    StaggeredPriceReferenceEnum,
    FunctionEnum,
} from '@rza-mean/api-interfaces';

export interface BaseType {
    _id: string;
}

export interface ProfessionBranch extends BaseType {
    name: string;
    backofficeEmail?: string;
    identifier?: string;
    tos?: string;
    hasOnboarding?: boolean;
    iconFile?: CustomFile;
}

export type MinimalProfessionBranch = Pick<ProfessionBranch, 'name' | 'iconFile'>;

export interface MinimalProfession extends BaseType {
    identifier?: string;
    name: string;
    status: ProfessionStatusEnum[keyof ProfessionStatusEnum];
    professionBranch: ProfessionBranch;
}

export type DropDownProfessionBranch = {
    label: string;
    items: MinimalProfession[];
};

export interface MinimalProfessionsGroupedByBranches {
    [key: string]: MinimalProfession[];
}

export interface Profession extends BaseType {
    name: string;
    identifier?: string;
    products?: Product[];
    status: ProfessionStatusEnum[keyof ProfessionStatusEnum];
    phone: string;
    email: string;
    requestedDocuments?: Document[];
    defaultBusinessForm?: BusinessForm;
    professionBranch: ProfessionBranch;
    discounts?: Discount[];
    promotedBundles?: PromotedBundles[];
    navigationItems: NavigationItem[];
    prices?: Price[];
}

export interface DependentPrice {
    type: PriceDependencyTypeEnum[keyof PriceDependencyTypeEnum];
    referenceId: string;
    price: Price;
}

export interface StaggeredPrice {
    lowerThreshold: number;
    upperThreshold: number;
    reference: StaggeredPriceReferenceEnum[keyof StaggeredPriceReferenceEnum];
    price: Price;
}

export interface Product extends BaseType {
    name: string;
    identifier?: string;
    shortDescription?: string;
    description?: string;
    modalContent?: ModalContent;
    deleted?: boolean;
    orderPriority?: number;
    promotion?: Promotion;
    prices?: Price[];
    dependentPrices?: DependentPrice[];
    staggeredPrices?: StaggeredPrice[];
    type: ProductTypeEnum[keyof ProductTypeEnum];
    category?: ProductCategoryEnum[keyof ProductCategoryEnum];
    note?: string;
    color?: string;
    icon?: string | CustomFile;
    iconFile?: CustomFile;
    itemLists?: ItemList[];
    defaultActive?: boolean;
    isObligatory?: boolean;
    paymentSpeed?: PaymentSpeed;
    revenue?: Revenue;
    forRevenueOnly?: boolean;
    products?: Product[];
    isForStartUpOnly?: boolean;
    isOmittedFromSelection?: boolean;
}

export interface BusinessForm extends BaseType {
    name: string;
    requestedDocuments?: Document[];
}

export interface Discount extends BaseType {
    name: string;
    identifier?: string;
    description?: string;
    requirement?: Requirement;
    expirationDate?: Date;
    price?: Price;
    staggeredPrices?: StaggeredPrice[];
    code?: string;
    discountedProducts: string[];
    blacklistProducts: string[];
    isInBundle?: boolean;
    isVisible?: boolean;
    calculationMethod: CalculationMethodEnum[keyof CalculationMethodEnum];
    weight?: number;
}

export interface DiscountCode extends BaseType {
    name: string;
    identifier?: string;
    description?: string;
    expirationDate?: Date;
    code: string;
    isVisible?: boolean;
    weight?: number;
    discounts: Discount[];
}

export interface Promotion extends BaseType {
    title: string;
    subtitle?: string;
    description?: string;
    icon?: string | CustomFile;
    iconFile?: CustomFile;
    buttonSnippet?: string;
    modalContent?: ModalContent;
    category: string;
    term?: string;
    color?: string;
    backgroundColor?: string;
    features: ItemList[];
    visibleDiscounts?: Price[];
    smallPrint?: string[];
}

export interface FunctionalPrice {
    functionName: FunctionEnum[keyof FunctionEnum];
    parameters?: Record<string, number>;
    userInput?: string[];
}

export interface FunctionalPriceForCalculation extends Omit<FunctionalPrice, 'userInput'> {
    userInput?: Record<string, unknown>;
}

export interface Price extends BaseType {
    name?: string;
    shortName?: string;
    value: number;
    unit: PriceUnitEnum[keyof PriceUnitEnum];
    billingType: BillingType;
    isVariable?: boolean;
    smallPrint?: string[];
    appendix?: string;
    minPrice?: Price;
    minPricePerUnit?: Price;
    customValues?: Record<string, ConditionPrice | OptionPrice>;
    description?: string;
    functionalPrice?: FunctionalPrice;
}

interface ConditionPrice {
    key?: string;
    name?: string;
    conditions?: {
        operator?: string;
        threshold?: number;
        value?: number;
    }[];
}

interface OptionPrice {
    name?: string;
    options: {
        name?: string;
        parameter?: number;
        value?: number;
    };
}

export interface CustomFile extends BaseType {
    path?: string;
    destination: string;
    fieldName?: string;
    mimeType?: string;
    name?: string;
    type: FileTypeEnum[keyof FileTypeEnum];
}

export interface ItemList extends BaseType {
    name: string;
    items: string[];
    listType: ListType;
}

export interface PaymentSpeed extends BaseType {
    value: string;
    unit: string;
    milliseconds: number;
    smallPrint?: string[];
}

export interface Revenue extends BaseType {
    count?: number;
    maxCount: number;
    itemValue?: number;
    value?: number;
    type: RevenueTypeEnum[keyof RevenueTypeEnum];
    factor?: number;
}

export interface Document extends BaseType {
    isObligatory?: boolean;
    documentType: DocumentType;
    uploadedFile?: CustomFile;
    handInLater?: boolean;
}

export interface DocumentType extends BaseType {
    name?: string;
    description?: string;
    category: DocumentCategoryEnum[keyof DocumentCategoryEnum];
}

export interface Requirement extends BaseType {
    operator: OperatorEnum[keyof OperatorEnum];
    professions: string[]; // _id
    products: string[]; // _id
    bundles: string[]; // _id
    requirements: Requirement[];
}

export interface PromotionCategory extends BaseType {
    name: string;
}

export interface BillingType extends BaseType {
    name: string;
    displayName: string;
    altName: string;
}

export interface ListType extends BaseType {
    name: string;
}

export interface PromotedBundles extends BaseType {
    promotion?: Promotion;
    bundles: Bundle[];
}

export interface Bundle extends BaseType {
    name?: string;
    config?: Configuration;
    code?: string;
    promotion?: Promotion;
    discounts?: Discount[];
    // settlementForRevenueOnly?: string[];
    settlementForRevenueOnly?: boolean;
    excludedProducts?: string[];
    tos?: string;
}

export interface Configuration {
    configuredProfession?: ConfiguredProfession;
    version: string;
    code?: string;
    isStartUpConfirmed?: boolean;
    _id?: string;
}

export interface ConfiguredProfession {
    profession: string; // id
    products: Product[];
    bundle?: string;
    discounts?: Discount[];
}

export interface NavigationItem extends BaseType {
    url: string;
    title: string;
    subtitle?: string;
    navigationText?: string;
    order: number;
    dependsOn?: {
        product?: string;
        role?: string;
        inverse?: boolean;
    };
}

export interface Breadcrumb extends NavigationItem {
    visited?: boolean;
    isCurrent?: boolean;
    disabled?: boolean;
    hidden?: boolean;
}

export interface Session extends BaseType {
    cookie: {
        path: string;
        _expires: string; //"2022-10-20T08:45:08.434Z",
        originalMaxAge: number; //604731845,
        httpOnly: boolean;
    };
    sid: string;
    config?: Configuration;
    document: Document[];
    company?: Company;
    customer?: Customer;
    isCompleted?: boolean;
    isStartUpConfirmed?: boolean;
}

export interface Company extends CompanySubsidiary {
    name: string;
    businessForm: BusinessForm;
    address?: Address;
    contactPersons?: ContactPerson[];
    taxCountryCode?: TaxCountryCodeEnum[keyof TaxCountryCodeEnum];
    taxId?: string;
    subsidiaries?: CompanySubsidiary[];
}

export interface CompanySubsidiary {
    _id?: string;
    contact?: CompanyPerson;
    institutionCode?: string;
}

export interface CompanyIdentificationForm {
    institutionCode: string;
    taxCountryCode: TaxCountryCodeEnum[keyof TaxCountryCodeEnum];
    taxId: string;
}

export interface CompanyForm {
    name: string;
    businessForm: BusinessForm;
}

export interface Person {
    _id?: string;
    firstname: string;
    lastname: string;
    email: string;
    phone?: string;
    salutation: SalutationEnum[keyof SalutationEnum];
    title?: TitleEnum[keyof TitleEnum];
    address: Address;
}

export interface CompanyPerson extends Person {
    fax?: string;
    isPep: boolean;
}

export interface ContactPerson extends Person {
    type: ContactPersonTypeEnum[keyof ContactPersonTypeEnum];
    position: string;
    isOKCContact: boolean;
    isPep?: boolean;
    country?: CountryEnum[keyof CountryEnum];
}

export interface Address {
    _id?: string;
    street: string;
    zip: string;
    city: string;
    country?: string;
}

export interface Customer extends BaseType {
    previousSettlementCenter?: PreviousSettlementCenter;
    receiptEntranceNotification?: ReceiptEntranceNotification;
    receiptSubmission?: ReceiptSubmission;
    bankCredentials?: BankCredentials;
    unionName?: string;
    recommendedBy?: string;
}

export interface BankCredentials {
    owner: string;
    institute: string;
    iban: string;
    bic: string;
}

export interface PreviousSettlementCenter {
    name: SettlementCenterEnum[keyof SettlementCenterEnum];
    customName?: string;
    termination?: Date;
    document?: Document;
}

export interface ReceiptEntranceNotification {
    mail?: string;
    phone?: string;
}

export interface ReceiptSubmission {
    interval: SubmissionIntervalEnum[keyof SubmissionIntervalEnum];
    firstSubmission: Date;
}

export interface Feedback {
    email?: string;
    subject: string;
    message: string;
}

export interface ContactRequest {
    firstname: string;
    lastname: string;
    salutation: string;
    phone: string;
    email: string;
    subject: string;
    message: string;
    profession: string;
    companyName: string;
}

export interface ModalContent {
    title?: string;
    content: string;
}
