<div class="flex border-y py-2">
    <i
        class="pi cursor-pointer text-muted hover:text-primary px-2 mr-3 {{ iconClass }}"
        style="font-size: 1.5rem"
        (click)="toggleSize.emit()"
    ></i>
    <div class="flex-grow pr-3">
        <ng-content></ng-content>
    </div>
</div>
