import { AfterViewInit, Component, ContentChildren, OnDestroy, QueryList } from '@angular/core';
import { animate, animateChild, group, query, state, style, transition, trigger } from '@angular/animations';
import { SidebarItemComponent } from './sidebar-item/sidebar-item.component';
import { Observable, Subject, takeUntil } from 'rxjs';
import { Product } from '../../shared/interfaces';
import { Store } from '@ngrx/store';
import { selectSettlementProducts } from '../../store/profession/profession.selectors';

@Component({
    selector: 'rza-mean-sidebar-old',
    templateUrl: './sidebar.component.html',
    styleUrls: ['./sidebar.component.scss'],
    animations: [
        trigger('sidebar', [
            state(
                'open',
                style({
                    width: '400px',
                })
            ),
            state(
                'closed',
                style({
                    width: '2.5rem',
                })
            ),
            transition('open => closed', [group([animate('.4s ease-in-out'), query('@rotateToggle', animateChild())])]),
            transition('closed => open', [group([animate('.4s ease-in-out'), query('@rotateToggle', animateChild())])]),
        ]),
        trigger('rotateToggle', [
            state(
                'open',
                style({
                    transform: 'rotate(180deg)',
                })
            ),
            state(
                'closed',
                style({
                    transform: 'rotate(0deg)',
                })
            ),
            transition('open <=> closed', [animate(500)]),
        ]),
    ],
})
export class SidebarComponent implements AfterViewInit, OnDestroy {
    @ContentChildren(SidebarItemComponent) items: QueryList<SidebarItemComponent> | undefined;

    public icons: string[] = [];
    public isOpen: boolean;
    private unsubscribe = new Subject();
    public settlementProducts$: Observable<Product[]> | undefined;

    constructor(private store: Store) {
        this.isOpen = false;
        this.settlementProducts$ = this.store.select(selectSettlementProducts);
    }

    ngAfterViewInit() {
        if (this.items) {
            Promise.resolve(null).then(() => {
                this.icons = this.items?.map((i) => i.iconClass || '') || [];
            });
            this.items.forEach((item) => {
                // const i = this.renderer.createElement('i');
                // this.renderer.addClass(i, 'pi cursor-pointer text-muted hover:text-primary px-2 mr-3 ' + item.iconClass);
                // this.renderer.setStyle(i, 'font-size', '1.5rem');
                // this.renderer.appendChild(this.icons?.nativeElement, i);
                item.toggleSize.pipe(takeUntil(this.unsubscribe)).subscribe(() => this.toggleSize());
            });
        }
    }

    ngOnDestroy() {
        this.unsubscribe.next(null);
        this.unsubscribe.complete();
    }

    toggleSize() {
        this.isOpen = !this.isOpen;
    }
}
