import {
    AfterViewInit,
    ContentChild,
    ContentChildren,
    Directive,
    ElementRef,
    EventEmitter,
    HostBinding,
    HostListener,
    Input,
    NgModule,
    OnDestroy,
    Output,
    QueryList,
    Renderer2,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { race, repeat, Subject, take, takeUntil } from 'rxjs';
import { hideScrollbar, isDescendantOf } from '../../misc/utils';

export type VerticalPosition = 'auto' | 'bottom' | 'top';

@Directive({
    selector: '[rzaMeanDropdownToggle]',
})
export class DropdownToggleDirective {
    onClick$: Subject<void>;

    @HostListener('click')
    private onClick() {
        this.onClick$.next();
    }

    constructor(public elementRef: ElementRef) {
        this.onClick$ = new Subject();
    }
}

@Directive({
    selector: '[rzaMeanDropdownMenu]',
})
export class DropdownMenuDirective {
    @Output() onClosed = new EventEmitter<void>();
    private position: VerticalPosition = 'auto';
    public isOpen = false;
    @HostBinding('style') style = {
        position: 'absolute',
        minWidth: 'max-content',
        width: '100%',
        display: 'none',
        overflowY: 'auto',
        zIndex: 1000,
    };
    private reference!: HTMLElement;

    constructor(public elementRef: ElementRef, private renderer: Renderer2) {}

    setReference(reference: HTMLElement) {
        this.reference = reference;
    }

    toggle() {
        if (this.elementRef.nativeElement.style.display === 'none') {
            this.open();
        } else {
            this.close();
        }
    }

    open() {
        this.positioning(this.position);
        this.isOpen = true;
    }

    private positioning(position: VerticalPosition): void {
        setTimeout(() => {
            this.renderer.setStyle(this.elementRef.nativeElement, 'visibility', 'hidden');
            this.renderer.setStyle(this.elementRef.nativeElement, 'display', 'block');
            this.renderer.setStyle(this.elementRef.nativeElement, 'height', 'auto');

            const elementHeight = this.elementRef.nativeElement.getBoundingClientRect().height;
            let availableHeight = 0;
            const availableHeightTop = this.reference.getBoundingClientRect().y;
            const availableHeightBottom =
                window.innerHeight - this.reference.getBoundingClientRect().y - this.reference.getBoundingClientRect().height;

            if (position === 'auto') {
                return this.positioning(availableHeightTop > availableHeightBottom ? 'top' : 'bottom');
            }
            if (position === 'top') {
                availableHeight = availableHeightTop;
                this.renderer.setStyle(this.elementRef.nativeElement, 'bottom', '100%');
            }
            if (position === 'bottom') {
                availableHeight = availableHeightBottom;
                this.renderer.removeStyle(this.elementRef.nativeElement, 'bottom');
            }

            if (availableHeight < elementHeight) {
                this.renderer.setStyle(
                    this.elementRef.nativeElement,
                    'height',
                    (availableHeight < 300 ? 300 : availableHeight) - 10 + 'px'
                );
            }

            this.renderer.setStyle(this.elementRef.nativeElement, 'visibility', 'visible');
        }, 0);
    }

    close() {
        this.renderer.setStyle(this.elementRef.nativeElement, 'display', 'none');
        this.onClosed.emit();
        this.isOpen = false;
    }

    setPosition(position: VerticalPosition) {
        this.position = position;
    }
}

@Directive({
    selector: '[rzaMeanDropdownItem]',
})
export class DropdownItemDirective {
    onClick$: Subject<void>;

    @HostListener('click')
    private onClick() {
        this.onClick$.next();
    }

    constructor(public elementRef: ElementRef) {
        this.onClick$ = new Subject();
    }
}

@Directive({
    selector: '[rzaMeanDropdown]',
})
export class DropdownDirective implements AfterViewInit, OnDestroy {
    @Input() position: VerticalPosition = 'auto';

    private unsubscribe$: Subject<void>;

    @ContentChild(DropdownMenuDirective)
    private menu!: DropdownMenuDirective;

    @ContentChild(DropdownToggleDirective)
    private toggle!: DropdownToggleDirective;

    @ContentChildren(DropdownItemDirective, { descendants: true })
    private items!: QueryList<DropdownItemDirective>;

    //TODO: probably broken with multiple dropdowns in one page
    @HostListener('document:click', ['$event.target'])
    private onClick(target: HTMLElement) {
        if (!isDescendantOf(this.toggle.elementRef.nativeElement, target) && !isDescendantOf(this.menu.elementRef.nativeElement, target)) {
            this.menu.close();
        }
    }

    constructor(private elementRef: ElementRef, private renderer: Renderer2) {
        this.unsubscribe$ = new Subject();
    }

    ngAfterViewInit() {
        this.renderer.setStyle(this.elementRef.nativeElement, 'position', 'relative');

        this.menu.setReference(this.toggle.elementRef.nativeElement);

        this.menu.setPosition(this.position);

        this.closeMenuOnItemClick();

        this.items.changes.pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
            this.closeMenuOnItemClick();
        });

        this.toggle.onClick$.pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
            if (!this.menu.isOpen) {
                const showScrollbar = hideScrollbar();
                this.menu.onClosed.pipe(take(1)).subscribe(showScrollbar);
            }
            this.menu.toggle();
        });
    }

    private closeMenuOnItemClick() {
        if (this.items.length) {
            race(this.items.map((item: DropdownItemDirective) => item.onClick$))
                .pipe(takeUntil(this.unsubscribe$), take(1), repeat())
                .subscribe(() => {
                    this.menu.close();
                });
        }
    }

    ngOnDestroy() {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    close() {
        this.menu.close();
    }
}

// @Component({
//     selector: 'rza-mean-dropdown-toggle-button',
//     template: `<button type="button" class="btn btn-primary"><ng-content></ng-content></button>`,
// })
// export class DropdownToggleButtonComponent implements OnInit {
//     constructor() {}
//
//     ngOnInit(): void {}
// }

@NgModule({
    imports: [CommonModule],
    declarations: [DropdownDirective, DropdownToggleDirective, DropdownMenuDirective, DropdownItemDirective],
    exports: [DropdownDirective, DropdownToggleDirective, DropdownMenuDirective, DropdownItemDirective],
})
export class DropdownDirectiveModule {}
